<template>
  <v-container class="container" fluid style="position: relative">
    <canvas
      id="overlay"
      width="600"
      height="600"
      style="
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 1;
      ">
    </canvas>
    <canvas
      id="chart"
      class="chart"
      width="600"
      height="600"
      style="top: 0; left: 0; z-index: 2">
    </canvas>
    <v-row dense justify="center">
      <v-btn small text @click="showInfo = true" class="text-capitalize">
        <v-icon small left>mdi-camera-control</v-icon>
        Controls
      </v-btn>
      <v-btn small text @click="resetZoom" class="text-capitalize ml-2">
        <v-icon small left>mdi-magnify-minus-outline</v-icon>
        Reset Zoom
      </v-btn>
    </v-row>
    <ChartInfo :value.sync="showInfo" />
  </v-container>
</template>

<script>
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import 'chartjs-adapter-date-fns'

export default {
  name: 'Chart',
  components: {
    ChartInfo: () => import('@/components/dialogs/ChartInfo')
  },
  props: {
    chartData: {
      type: Object
    },
    chartOptions: {
      type: Object
    },
    dates: {
      type: Object
    }
  },
  data() {
    return {
      chart: {},
      showInfo: false,
      loading: true
    }
  },
  watch: {
    chartData: {
      handler() {
        this.chart.update('none')
      },
      deep: true
    }
  },
  mounted() {
    Chart.register(zoomPlugin)
    this.createChart()
  },
  methods: {
    createChart() {
      const options = {
        data: this.chartData,
        options: this.chartOptions
      }
      const ctx = document.getElementById('chart')
      this.chart = new Chart(ctx, options)

      const overlay = document.getElementById('overlay')
      let startIndex = 0
      overlay.width = ctx.width
      overlay.height = ctx.height
      const selectionContext = overlay.getContext('2d')
      const selectionRect = {
        w: 0,
        startX: 0,
        startY: 0
      }
      let drag = false

      ctx.addEventListener('pointerdown', evt => {
        const points = this.chart.getElementsAtEventForMode(evt, 'index', {
          intersect: false
        })
        if (points.length > 0) {
          startIndex = points[0].index
        }
        const rect = ctx.getBoundingClientRect()
        selectionContext.globalAlpha = 0
        selectionRect.startX = evt.clientX - rect.left
        selectionRect.startY = this.chart.chartArea.top
        drag = true
      })

      ctx.addEventListener('pointermove', evt => {
        const rect = ctx.getBoundingClientRect()
        if (drag) {
          selectionRect.w = evt.clientX - rect.left - selectionRect.startX
          selectionContext.clearRect(0, 0, ctx.width, ctx.height)
          selectionContext.fillRect(
            selectionRect.startX,
            selectionRect.startY,
            selectionRect.w,
            this.chart.chartArea.bottom - this.chart.chartArea.top
          )
        } else {
          selectionContext.clearRect(0, 0, ctx.width, ctx.height)
          const x = evt.clientX - rect.left
          if (x > this.chart.chartArea.left) {
            selectionContext.fillRect(
              x,
              this.chart.chartArea.top,
              1,
              this.chart.chartArea.bottom - this.chart.chartArea.top
            )
          }
        }
      })

      ctx.addEventListener('pointerup', evt => {
        const points = this.chart.getElementsAtEventForMode(evt, 'index', {
          intersect: false
        })

        selectionContext.clearRect(0, 0, ctx.width, ctx.height)
        drag = false
        if (points.length > 0) {
          const startDate = options.data.labels[startIndex]
          const endDate = options.data.labels[points[0].index]
          this.$emit('setDates', [startDate, endDate])
        }
      })
    },
    resetZoom() {
      this.chart.resetZoom()
      this.$emit('resetChart')
    },
    setValue(value) {
      if (!value) return
      this.$emit('update', value)
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  background: #fff;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
}
.container:hover {
  border: 1px solid #000;
}
.chart {
  cursor: pointer;
  max-height: 550px;
  padding: 15px 10px 10px 10px;
}
</style>
